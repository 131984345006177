/* eslint-disable class-methods-use-this */
import _axios, { get, put, post, _delete } from '@/lin/plugin/axios'

class Stastics {
  // 首页综合统计
  async getMultipleStatistics(data) {
    const res = await get('/v1/index/multiple_statistics', data)
    return res
  }

  // 进行中的活动列表
  async getOnGoingActivity(data) {
    const res = await get('/v1/index/start_activitiy', data)
    return res
  }

  // 即将结束的活动列表
  async getOnEndingActivity(data) {
    const res = await get('/v1/index/almost_over_activitiy', data)
    return res
  }

  // 曲线图数据统计
  async getGraphStatistics(data) {
    const res = await get('/v1/index/graph_statistics', data)
    return res
  }

  // 发放记录
  async getSendRecord(data) {
    const res = await get('/v1/coupon/sent_list', data)
    return res
  }

  // 发放记录统计
  async sendStatistics(data) {
    const res = await get('/v1/coupon/sent_statistics', data)
    return res
  }

  // 核销记录
  async getUseRecord(data) {
    const res = await get('/v1/coupon/exchange_list', data)
    return res
  }

  // 核销记录统计
  async useStatistics(data) {
    const res = await get('/v1/coupon/exchange_statistics', data)
    return res
  }

  // 优惠券统计
  async getCouponStatistic(data) {
    const res = await get('/v1/coupon/activity_statistics', data)
    return res
  }

  // 优惠券统计导出
  async couponStatisticExport(data) {
    const res = await get('/v1/coupon/activity_statistics_export', data)
    return res
  }

  // 商家统计
  async getMerchantStastic(data) {
    const res = await get('/v1/coupon/bus_statistics', data)
    return res
  }

  // 商家统计导出
  async merStatisticExport(data) {
    const res = await get('/v1/coupon/bus_statistics_export', data)
    return res
  }
}

export default new Stastics()
